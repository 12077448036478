import axios from 'axios';
import { useMutation } from 'react-query';
import { API } from '../../../../../utils/http';

async function signUpMutation({ email, password }) {
  return axios.post(`${API}/users/signup`, {
    email,
    password,
  });
}

export const signUpUseCase = () => {
  const { isSuccess, data, isLoading, error, mutate } = useMutation((e) =>
    signUpMutation(e),
  );

  return {
    isSuccessSignUpUseCase: isSuccess,
    dataSignUpUseCase: data,
    isLoadingSignUpUseCase: isLoading,
    errorSignUpUseCase: error,
    mutateSignUp: mutate,
  };
};
